<template>
  <!-- terminal START -->
  <div class="scan-terminal">
    <!-- qr-code:icon START -->
    <div class="icon">
      <img width="64" :src="iconTerminal" />
    </div>
    <!-- qr-code:icon END -->

    <h1 v-html="$t('terminal.terminal.headline')"></h1>

    <!-- qr-code:description START -->
    <div class="description description-links">
      <p v-html="$t('terminal.terminal.description')"></p>
    </div>
    <!-- qr-code:description START -->

    <router-link
      class="btn-default"
      :to="'/scan/terminal/terminal/terminal'"
    >{{ $t('qrcode.scanqrcode') }}</router-link>
  </div>
  <!-- terminal END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

import ICONS from '../../utils/icons';

const iCode = require('../../assets/images/temp/qr-code.png');
const iCodeG = require('../../assets/images/temp/qr-code-green.png');

const {
  iTerminal,
} = ICONS;

export default {
  name: 'terminal',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('terminal.terminal.headline'),

      iconCode: localStorage && localStorage.getItem('theme') === 'light' ? iCode : iCodeG,
      iconTerminal: localStorage && localStorage.getItem('theme') === 'light' ? iTerminal.defaultUrl : iTerminal.greenUrl,
    };
  },
};
</script>
